import React from "react";

const ActiveIndex = ({ activeIndex, length, setActiveIndex }) => {
  return (
    <div className="activeindex">
      {Array(length)
        .fill(0)
        .map((_, i) => (
          <div
            className={
              activeIndex === i ? "activeindex-dot active" : "activeindex-dot"
            }
            onClick={() => setActiveIndex(i)}
            key={i}
          />
        ))}
    </div>
  );
};

export default ActiveIndex;
