import React from "react";
import {useAuth} from "react-uicomp"
const Disclaimer = () => {
  const {language} = useAuth()
  return (
    <div className="disclaimer-container">
      <h3>{language.key.disclaimer}</h3>
      <p>
        This research was funded by the National Institure for Health Research (
        NIHR ) ( Project ref 16/137/49 ) using UK aid from the UK Government to
        support global health research. The views expressed in this publication
        are those of the author(s) and not necessarily those of the NHR or the
        UK Department of Health and Social Care.
      </p>
    </div>
  );
};

export default Disclaimer;
