import React from "react";
import { useNavigation } from "react-uicomp";
import PropTypes from "prop-types";
import { FaChevronRight } from "react-icons/all";

const SeeMore = ({ title, icon, seeAllRoute }) => {
  const { navigation } = useNavigation();
  return (
    <div
      className="seemore-container"
      onClick={() => navigation.navigate(seeAllRoute)}>
      <span className="seemore">{title}</span>

      <span className="seemore-icon">{icon ? icon : <FaChevronRight />}</span>
    </div>
  );
};

SeeMore.propTypes = {
  title: PropTypes.string,
};
SeeMore.defaultProps = {
  title: "See All",
};

export const SeeMoreRes = ({ title, icon }) => {
  return (
    <div className="seemoreres-container">
      <span className="seemoreres">{title}</span>

      <span className="seemoreres-icon">
        {icon ? icon : <FaChevronRight />}
      </span>
    </div>
  );
};

SeeMoreRes.propTypes = {
  title: PropTypes.string,
};
SeeMoreRes.defaultProps = {
  title: "See All",
};

export default SeeMore;
