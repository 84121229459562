import React, { useState, useRef } from "react";
import {
  useOutsideClick,
  useMountedValue,
  AnimatedBlock,
  interpolate,
  useAuth,
} from "react-uicomp";
import { FaCaretDown } from "react-icons/all";

import UK from "../../../assets/icons/uk.png";
import NEPAL from "../../../assets/icons/nepal.png";

const LanguageDropdown = () => {
  const dropdownRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const mountedValue = useMountedValue(dropdownVisible, [0, 1, 0]);
  const { setEnglishLanguage, setNepaliLanguage, language } = useAuth();
  const { isNepali } = language;

  useOutsideClick(dropdownRef, function () {
    setDropdownVisible(false);
  });

  const outputRange = [
    "scale(0.8, 0.6) translateX(0%)",
    "scale(1, 1) translateX(0%)",
  ];

  return (
    <div ref={dropdownRef} style={{ position: "relative" }}>
      <div
        className="selectlanguage"
        onClick={() => setDropdownVisible((prev) => !prev)}>
        <div className="selectlanguage-flag">
          <img src={isNepali ? NEPAL : UK} alt="flag" />
        </div>
        <div className="selectlanguage-alias">{`${
          isNepali ? "ने" : "EN"
        }`}</div>
        <div
          className="selectlanguage-down"
          style={{
            transformOrigin: "55% 45%",
            transform: dropdownVisible ? "rotate(180deg)" : "rotate(0deg)",
          }}>
          <FaCaretDown />
        </div>
      </div>
      {mountedValue(
        (animation, visible) =>
          visible && (
            <AnimatedBlock
              className="languagedropdown"
              style={{
                transformOrigin: "80% 0%",
                right: 0,
                opacity: animation.value,
                transform: interpolate(animation.value, [0, 1], outputRange),
              }}>
              {isNepali ? (
                <div
                  className="selectlanguage"
                  onClick={() => {
                    setEnglishLanguage();
                    setDropdownVisible((prev) => !prev);
                  }}>
                  <div className="selectlanguage-flag">
                    <img src={UK} alt="uk-flag" />
                  </div>
                  <div className="selectlanguage-alias">EN</div>
                </div>
              ) : (
                <div
                  className="selectlanguage"
                  onClick={() => {
                    setNepaliLanguage();
                    setDropdownVisible((prev) => !prev);
                  }}>
                  <div className="selectlanguage-flag">
                    <img src={NEPAL} alt="nep-flag" />
                  </div>
                  <div className="selectlanguage-alias">ने</div>
                </div>
              )}
            </AnimatedBlock>
          ),
      )}
    </div>
  );
};

export default LanguageDropdown;
