import React, { useEffect } from "react";
import { useAuth } from "react-uicomp";
import { ContactForm, Information } from "./component";
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postContactAction } from "../../actions/Actions";
import Map from "../common/map/mapcomponent.common";

const ContactUsPage = (props) => {
  const { setHeaderTitle, language } = useAuth();

  useEffect(() => {
    setHeaderTitle(language.key.contact);
  }, [setHeaderTitle, language.key.contact]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CompWrapper>
      <div className="contact-page-container">
        <ContactForm {...props} />
        <Information />
      </div>
      <div style={{ marginTop: 20 }}>
        <Map location={[27.684315, 85.410991]} />
      </div>
      {/* <MapContainer /> */}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contact,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postContactAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
