import { MEMBER_TYPES } from "../constant/memberTypes.constant";

export const teamFormatter = function (list) {
  const formattedList = list.map((obj) => ({
    ...obj,
    description: { ...obj.description, image: obj.image },
  }));

  return Object.keys(MEMBER_TYPES)
    .map((type) => {
      const data = formattedList.filter((val) => val.role === type);
      return { type, data };
    })
    .reduce((acc, curr) => {
      acc[curr.type] = curr.data;
      return acc;
    }, {});
};
