import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useAuth } from "react-uicomp";

import ArticleCard from "../common/articleCard/ArticleCard.common";
import { NoDataUI } from "../common/noDataUI/noDataUI.common";
import Title from "../common/title/Title.common";
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";
import ActivityIndicator from "../hoc/activityIndicator/ActivityIndicator.hoc";

// ACTIONs
import { getMediaAction } from "../../actions/Actions";

const MediaOutputsPage = (props) => {
  const { setHeaderTitle, language, researchProgram } = useAuth();
  const { getMediaAction, media } = props;
  const { listLoader, list } = media;

  useEffect(() => {
    setHeaderTitle(language.key.mediaOutput);
  }, [setHeaderTitle, language.key.mediaOutput]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getMediaAction(researchProgram?.id);
  }, [getMediaAction, researchProgram]);

  return (
    <CompWrapper>
      <Title title={language.key.mediaOutput} hide_SeeAll />
      <ActivityIndicator animating={listLoader}>
        {list.length > 0 ? (
          <div className="news-container">
            {list.map((val, index) => {
              return (
                <div key={index}>
                  <ArticleCard data={val} />
                </div>
              );
            })}
          </div>
        ) : <NoDataUI />}
      </ActivityIndicator>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    media: state.media,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMediaAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaOutputsPage);
