import React from "react";
import { useAuth, useNavigation } from "react-uicomp";
import { FILE_URL } from "../../../config/Config";
import { getCurrentLanguage } from "../../../utils/getCurrentLanguage.util";

import SeeMore from "../../common/seemore/Seemore.common";
import { NoDataUI } from "../../common/noDataUI/noDataUI.common";

export const Article = ({ data }) => {
  const { language } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;

  const currentLanguage = getCurrentLanguage(language);
  return (
    <div className="article-container">
      <div className="article">
        <div className="article-head">
          <div className="article-head-title">{language.key.events}</div>
          <div className="article-head-seeall">
            <SeeMore
              title={language.key.seeall}
              seeAllRoute={routes.Events.path}
            />
          </div>
        </div>
        <div className="article-body">
          {data?.length ? data.slice(0, 3).map((val, index) => {
            return (
              <div key={index} className="article-body-content">
                <div
                  className="image-container"
                  style={{
                    backgroundImage: `url(${FILE_URL + val.thumbnail})`,
                  }}></div>
                <div className="article-body-content-desc">
                  <div className="article-body-content-desc-title">
                    {val.description?.title[currentLanguage]}
                  </div>

                  <p className="article-body-content-desc-body">
                    {val.description?.shortDesc[currentLanguage]}
                  </p>

                  <div
                    className="article-body-content-desc-footer"
                    onClick={() => {
                      navigate(routes.Events.path + `/${val.id}`);
                    }}>
                    <SeeMore title={language.key.seeall} />
                  </div>
                </div>
              </div>
            );
          }) : <NoDataUI message='No Events Available' />}
        </div>
      </div>
    </div>
  );
};
