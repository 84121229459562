import React from "react";
import { useAuth, useNavigation } from "react-uicomp";
import {
  FaFacebookSquare,
  FaTwitter,
  MdHome,
  MdLocalPhone,
  MdEmail,
  MdWatchLater,
} from "react-icons/all";

// IMPORT ICONS
import Codniv_Logo from "../../../assets/icons/Codniv_logo.png";

const Footer = () => {
  const { language } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-links">
          <div className="footer-links-block first">
            <div className="footer-title">{language.key.nirc}</div>

            <div className="footer-desc">{language.key.footer.footerDesc}</div>

            <div className="footer-icons">
              <div
                className="footer-icons-image"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/Nepal-Injury-Research-Centre-NIRC-1614050432226838",
                  )
                }>
                <FaFacebookSquare size={20} />
              </div>
              <div
                className="footer-icons-image"
                onClick={() => window.open("https://twitter.com/nirc_nepal")}>
                <FaTwitter size={20} />
              </div>
              {/* <div
                className="footer-icons-image"
                onClick={() => window.open("https://instagram.com/codniv")}>
                <AiFillInstagram size={20} />
              </div> */}
            </div>
          </div>

          {/* <div className="footer-links-block">
            <div>
              <div className="footer-links-block-title">Web Login</div>
              <div className="footer-links-block-links">
                <input placeholder="Email" />
                <br />
                <input placeholder="Passsword" />
                <br />
                <SecondaryButton title="Login" />
              </div>
            </div>
          </div> */}

          <div className="footer-links-block">
            <div>
              <div className="footer-links-block-title">
                {language.key.quickLinks}
              </div>
              <ul className="footer-links-block-links">
                <li
                  className="links-item"
                  onClick={() => navigate(routes.Publication.path)}>
                  <span>{language.key.publication}</span>
                </li>
                <li
                  className="links-item"
                  onClick={() => navigate(routes.Newsletter.path)}>
                  <span>{language.key.newsletter}</span>
                </li>
                <li
                  className="links-item"
                  onClick={() => navigate(routes.StudyFundedPage.path)}>
                  <span>{language.key.currentResearch}</span>
                </li>
                <li
                  className="links-item"
                  onClick={() => navigate(routes.News.path)}>
                  <span>{language.key.news}</span>
                </li>
                <li
                  className="links-item"
                  onClick={() => navigate(routes.ContactUs.path)}>
                  <span>{language.key.contact}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-links-block">
            <div>
              <div className="footer-links-block-title">
                {language.key.contact}
              </div>
              <ul className="footer-links-block-links">
                <li className="links-item">
                  <span className="links-item-icon">
                    <MdHome size={20} />
                  </span>
                  <span>
                    {language.key.contactpage.address1}
                    <br /> {language.key.contactpage.address2}
                    <br /> {language.key.contactpage.address3}
                    <br /> {language.key.contactpage.address4}
                  </span>
                </li>
                <li className="links-item">
                  <span className="links-item-icon">
                    <MdWatchLater size={20} />
                  </span>
                  <span>
                    {language.key.openinghours} : {language.key.openingtime}
                  </span>
                </li>
                <li className="links-item">
                  <span className="links-item-icon">
                    <MdLocalPhone size={20} />
                  </span>
                  <span>{language.key.telephone}</span>
                </li>
                <li className="links-item">
                  <span className="links-item-icon">
                    <MdEmail size={20} />
                  </span>
                  <span>nirc@kmc.edu.np</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footerinfo-container">
        <div className="footerinfo">
          <div className="footerinfo-left">
            COPYRIGHT 2020. ALL RIGHTS RESERVED BY <span>NIRC</span>
          </div>
          <div
            className="footerinfo-right"
            onClick={() => window.open("https://codniv.com/")}>
            <span>Powered by</span>
            <img
              alt="Codniv Innovation Pvt. Ltd."
              title="Visit Codniv.com"
              src={Codniv_Logo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
