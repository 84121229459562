import React from "react";
import { useAuth } from "react-uicomp";
import { AiFillHome } from "react-icons/ai";
import { FaPhoneAlt, FaMapMarkerAlt, FaRegEnvelope } from "react-icons/fa";

const iconStyle = {
  height: 20,
  width: 20,
};

export const Information = () => {
  const { language } = useAuth();

  const INFO = [
    {
      desc: language.key.contactpage.fulladdress,
      icon: <AiFillHome style={iconStyle} />,
    },
    {
      desc: language.key.contactpage.pobox,
      icon: <FaMapMarkerAlt style={iconStyle} />,
    },
    {
      desc: language.key.contactpage.telephone,
      icon: <FaPhoneAlt style={iconStyle} />,
    },
    {
      desc: language.key.contactpage.emailaddress,
      icon: <FaRegEnvelope style={iconStyle} />,
    },
  ];
  return (
    <div className="contact-information-wrapper">
      <div className="contact-information-container">
        <div className="title">{language.key.contactpage.fulladdress}</div>
        {INFO.map((_, index) => {
          return (
            <div className="information">
              <div className="info-icon">{_.icon}</div>
              <div className="info-title">{_.desc}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
