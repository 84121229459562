import React, { useEffect } from "react";
import { useAuth } from "react-uicomp";
import CardWrapper from "../hoc/compWrapper/CardWrapper.hoc";
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";
import { Study } from "./component";

const OurResearchPage = () => {
  const { setHeaderTitle, language } = useAuth();

  useEffect(() => {
    setHeaderTitle(language.key.currentResearch);
  }, [setHeaderTitle, language.key.currentResearch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CompWrapper>
      <CardWrapper>
        <Study />
      </CardWrapper>
    </CompWrapper>
  );
};

export default OurResearchPage;
