import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-uicomp";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSelectedCategoryAction } from "../../../actions/Actions";
import { getCurrentLanguage } from "../../../utils/getCurrentLanguage.util";
import ActivityIndicator from "../../hoc/activityIndicator/ActivityIndicator.hoc";

import PLACEHOLDER from "../../../assets/images/placeholder.png";
import { FILE_URL } from "../../../config/Config";

const mapStateToProps = (state) => {
  return {
    research: state.research,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSelectedCategoryAction }, dispatch);
};

export const Study = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const { getSelectedCategoryAction, research } = props;
  const { researchLoader, researchList } = research;
  const { setHeaderTitle, language } = useAuth();
  const { params, navigation } = useNavigation();
  const { navigate } = navigation;
  const { title } = params;
  const currentLanguage = getCurrentLanguage(language);

  useEffect(() => {
    setHeaderTitle(language.key.research);
  }, [setHeaderTitle, language.key.research]);

  useEffect(() => {
    getSelectedCategoryAction(title);
  }, [getSelectedCategoryAction, title]);

  // console.log("research list", researchList);

  return (
    <div className="research-study-wrapper">
      <div className="container">
        <div className="content-wrapper">
          <div className="title">{language.key.whatwedo}</div>
          <ActivityIndicator animating={researchLoader}>
            <div className="study-wrapper">
              {researchList.length > 0
                ? researchList.map((val, index) => {
                  const id = val?.id;

                  const description = val?.description;
                  const title = description?.study[currentLanguage];
                  const background = description?.title[currentLanguage];
                  const thumbnail = val?.thumbnail;

                  return (
                    <div
                      className="study"
                      onClick={() => navigate(`/research-detail/${id}`)}
                      key={index}>
                      {/* <div className="study-icon">
                          <ImBook size={20} />
                        </div> */}
                      <div
                        className="study-image"
                        style={{
                          backgroundImage: `url(${thumbnail ? FILE_URL + thumbnail : PLACEHOLDER
                            })`,
                        }}></div>

                      <div className="study-title">{title}</div>

                      <div className="study-description">{background}</div>
                    </div>
                  );
                })
                : null}
            </div>
          </ActivityIndicator>
        </div>
      </div>
    </div>
  );
});
