import { NEWS, NEWS_DETAIL } from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  detailLoader: false,
  detail: null,
};

export function newsReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case NEWS.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case NEWS.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case NEWS.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // NEWS_DETAIL
    case NEWS_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case NEWS_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case NEWS_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };

    default:
      return state;
  }
}
