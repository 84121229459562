import React from "react";
import { useAuth, useNavigation } from "react-uicomp";
import SeeMore from "../seemore/Seemore.common";

const Title = ({ title, noBorder, hide_SeeAll, seeAllRoute }) => {
  const { language } = useAuth();
  const { navigation } = useNavigation();
  return (
    <div className={`title-container ${!noBorder && "border"}`}>
      <div className="title">{title}</div>
      {!hide_SeeAll && (
        <div className="right" onClick={() => navigation.navigate(seeAllRoute)}>
          <SeeMore title={language.key.seeall} />
        </div>
      )}
    </div>
  );
};

export default Title;
