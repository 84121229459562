import React from "react";
import { useAuth } from "react-uicomp";
import NewsCard from "../../common/newsCard/NewsCard.common";
import Title from "../../common/title/Title.common";

const MoreEvents = ({ data }) => {
  const { language } = useAuth();
  return (
    <div className="moreevents-container">
      <div className="moreevents">
        <Title title={language.key.moreEvents} hide_SeeAll />
        <div className="moreevents-news">
          {data.map((val, index) => {
            return (
              <div key={index}>
                <NewsCard data={val} gotoPage="Events" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MoreEvents;
