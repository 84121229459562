import React from "react";
import { useAuth, useNavigation } from "react-uicomp";
import { FaChevronRight } from "react-icons/all";

import Title from "../../common/title/Title.common";
import DateComponent from "../../common/date/Date.component";
import { SeeMoreRes } from "../../common/seemore/Seemore.common";

import { FILE_URL } from "../../../config/Config";

const RecentEvents = ({ data }) => {
  // const { id, thumbnail, description, pdf } = data;
  // const { title, shortDesc, date } = description;

  // console.log("recent data", data);
  const { language } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  return (
    <div className="recentevents-container">
      <Title title={language.key.recentEvents} hide_SeeAll />
      <div className="recentevents">
        <div
          className="leftimage"
          style={{
            backgroundImage: `url(${FILE_URL + data[0].thumbnail})`,
          }}>
          <div className="leftimage-overlay">
            <div className="leftimage-overlay-title">
              {language.isNepali
                ? data[0].description?.title?.np
                : data[0].description?.title?.en}
            </div>
            <div className="leftimage-overlay-content">
              {language.isNepali
                ? data[0].description?.shortDesc?.np
                : data[0].description?.shortDesc?.en}
            </div>
            <div className="leftimage-overlay-footer">
              <DateComponent date={data[0].created_at.slice(0, 10)} />
              <div
                className="leftimage-overlay-footer-seemorecontainer"
                onClick={() => {
                  navigate(routes.Events.path + `/${data[0].id}`);
                }}>
                <span className="seemore">{language.key.readmore}</span>

                <span className="seemore-icon">
                  <FaChevronRight />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="rightimage">
          {data.slice(1, 4).map((val, index) => {
            return (
              <div key={index} className="rightimage-card">
                <div
                  className="image"
                  style={{
                    backgroundImage: `url(${FILE_URL + val.thumbnail})`,
                  }}></div>
                <div className="body">
                  <div className="body-title">
                    {language.isNepali
                      ? val.description?.title?.np
                      : val.description?.title?.en}
                  </div>
                  <div className="body-content">
                    {language.isNepali
                      ? val.description?.shortDesc?.np
                      : val.description?.shortDesc?.en}
                  </div>
                  <div className="body-footer">
                    <DateComponent date={data[0].created_at.slice(0, 10)} />
                    <div
                      className="body-footer-seemore"
                      onClick={() => {
                        navigate(routes.Events.path + `/${val.id}`);
                      }}>
                      <SeeMoreRes title={language.key.readmore} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RecentEvents;
