import { CONTACT } from "./Actions";
import { api, APIS } from "../config/Config";

export function postContactAction(body, resetHandler, toast) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: CONTACT.LOADING });
      res = await api(APIS.contact, "POST", body);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: CONTACT.SUCCESS });
        toast({ message: "Successfully Submited!", type: "success" });
        resetHandler();
      } else {
        dispatch({ type: CONTACT.ERROR });
        toast({ message: data.message, type: "error" });
      }
    } catch ({ message }) {
      dispatch({ type: CONTACT.ERROR });
      console.error(message);
      toast({ message: "Error Submitting", type: "error" });
      return 0;
    }
  };
}
