import React, { useState } from "react";
import { useAuth } from "react-uicomp";

// COMMONS
import Title from "../../common/title/Title.common";
import ImageViewer from "../../common/imageViewer/ImageViewer.common";

// IMAGES
import IMAGE1 from "../../../assets/gallery/g1.jpg";
import IMAGE2 from "../../../assets/gallery/g2.jpg";
import IMAGE3 from "../../../assets/gallery/g3.jpg";
import IMAGE4 from "../../../assets/gallery/g4.jpg";
import IMAGE5 from "../../../assets/gallery/g5.jpg";
import IMAGE6 from "../../../assets/gallery/g6.jpg";
import IMAGE7 from "../../../assets/gallery/g7.jpg";

var items = [
  {
    id: 1,
    image: IMAGE1,
  },
  {
    id: 2,
    image: IMAGE2,
  },
  {
    id: 3,
    image: IMAGE3,
  },
  {
    id: 4,
    image: IMAGE4,
  },
  {
    id: 5,
    image: IMAGE5,
  },
  {
    id: 6,
    image: IMAGE6,
  },
  {
    id: 7,
    image: IMAGE7,
  },
];

export const Gallery = () => {
  const { language } = useAuth();
  const [activeImage, setActiveImage] = useState(null);

  return (
    <>
      <div className="gallery-container">
        <div className="gallery">
          <div className="gallery-title">
            <Title title={language.key.imagegallery} noBorder hide_SeeAll />
          </div>

          <div className="gallery-layout">
            <div className="gallery-layout-left">
              <div className="left">
                <div className="left-top galleryimg">
                  <img
                    src={items[0].image}
                    alt="Gallery"
                    onClick={() => setActiveImage(items[0].image)}
                  />
                </div>
                <div className="left-down galleryimg">
                  <img
                    src={items[1].image}
                    alt="Gallery"
                    onClick={() => setActiveImage(items[1].image)}
                  />
                </div>
              </div>
              <div className="middle">
                <div className="middle-top galleryimg">
                  <img
                    src={items[2].image}
                    alt="Gallery"
                    onClick={() => setActiveImage(items[2].image)}
                  />
                </div>
                <div className="middle-down galleryimg">
                  <img
                    src={items[3].image}
                    alt="Gallery"
                    onClick={() => setActiveImage(items[3].image)}
                  />
                </div>
              </div>
            </div>

            <div className="right">
              <div className="right-top galleryimg">
                <img
                  src={items[4].image}
                  alt="Gallery"
                  onClick={() => setActiveImage(items[4].image)}
                />
              </div>
              <div className="right-down">
                <div className="right-down-left galleryimg">
                  <img
                    src={items[5].image}
                    alt="Gallery"
                    onClick={() => setActiveImage(items[5].image)}
                  />
                </div>
                <div className="right-down-right galleryimg">
                  <img
                    src={items[6].image}
                    alt="Gallery"
                    onClick={() => setActiveImage(items[6].image)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImageViewer {...{ activeImage, setActiveImage }} />
    </>
  );
};
