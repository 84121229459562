import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-uicomp";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getStudyDetailAction } from "../../actions/Actions";
import { getCurrentLanguage } from "../../utils/getCurrentLanguage.util";
// import { DetailCarousel } from "../common/detailCarousel/DetailCarousel.common";
import ActivityIndicator from "../hoc/activityIndicator/ActivityIndicator.hoc";
import CardWrapper from "../hoc/compWrapper/CardWrapper.hoc";
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";
import SubTitle from "../common/subTitle/SubTitle.common";

const ResearchDetailPage = (props) => {
  const { study, getStudyDetailAction } = props;
  const { setHeaderTitle, language } = useAuth();
  const { params } = useNavigation();
  const { id } = params;
  const currentLanguage = getCurrentLanguage(language);
  const { detailLoader, detail } = study;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getStudyDetailAction(id);
  }, [getStudyDetailAction, id]);

  const description = detail?.description;
  const title = description?.title[currentLanguage];
  // const images = detail?.images;

  useEffect(() => {
    setHeaderTitle(title);
  }, [setHeaderTitle, title]);

  // console.log("research detail", detail);
  return (
    <CompWrapper header={"Research 1"}>
      <CardWrapper>
        <ActivityIndicator animating={detailLoader}>
          <div className="research-detail-wrapper">
            <div className="container">
              <div className="detail">
                <div className="title">{title}</div>
                {/* {images?.length ? (
                  <div className="image">
                    <DetailCarousel data={images} />
                  </div>
                ) : null} */}
                <div className="paragraph">
                  {/* {description &&
                    Object.keys(description)
                      .filter((title) => title !== "link")
                      .map((title, index) => {
                        return (
                          <SubTitle
                            key={index}
                            title={title}
                            description={description[title][currentLanguage]}
                          />
                        );
                      })} */}
                  {description && (
                    <>
                      <SubTitle
                        // key={index}
                        title={language.key.researchDetail.projectTitle}
                        description={description.title[currentLanguage]}
                      />
                      <SubTitle
                        // key={index}
                        title={language.key.researchDetail.background}
                        description={description.background[currentLanguage]}
                      />
                      <SubTitle
                        // key={index}
                        title={language.key.researchDetail.projectAim}
                        description={description.aim[currentLanguage]}
                      />
                      <SubTitle
                        // key={index}
                        title={language.key.researchDetail.methods}
                        description={description.method[currentLanguage]}
                      />
                      <SubTitle
                        // key={index}
                        title={language.key.researchDetail.impact}
                        description={description.impact[currentLanguage]}
                      />
                      <SubTitle
                        // key={index}
                        title={language.key.researchDetail.team}
                        description={description.team[currentLanguage]}
                      />
                    </>
                  )}
                  {description && description.link && (
                    <SubTitle
                      // key={index}
                      title={"link"}
                      description={description.link}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ActivityIndicator>
      </CardWrapper>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    study: state.study,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getStudyDetailAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ResearchDetailPage);
