import { EVENT, EVENT_DETAIL } from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  detailLoader: false,
  detail: null,
};

export function eventReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case EVENT.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case EVENT.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case EVENT.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    // EVENT_DETAIL
    case EVENT_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case EVENT_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case EVENT_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };

    default:
      return state;
  }
}
