import React, { useEffect } from "react";
import { useAuth } from "react-uicomp";
import {
  MainCarousel,
  Gallery,
  News,
  Collaborators,
  TopCard,
  Introduction,
  Article,
} from "./components";
import FundedBy from "./components/Fundedby.component";
import Disclaimer from "./components/Disclaimer.component";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getNewsAction, getEventAction } from "../../actions/Actions";

const HomePage = (props) => {
  const { setHeaderTitle, language, researchProgram } = useAuth();
  const { getNewsAction, getEventAction, news, event } = props;
  const { list: newsList } = news;
  const { list: eventList } = event;

  useEffect(() => {
    setHeaderTitle(language.key.home);
  }, [setHeaderTitle, language.key.home]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getNewsAction(researchProgram?.id);
  }, [getNewsAction, researchProgram]);

  useEffect(() => {
    getEventAction(researchProgram?.id);
  }, [getEventAction, researchProgram]);

  return (
    <div>
      <MainCarousel />
      <TopCard
        leftCard={<Introduction />}
        rightCard={<Article data={eventList} />}
      />
      <News data={newsList.slice(0, 10)} />
      <Collaborators />
      <Gallery />
      <FundedBy />
      <Disclaimer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    news: state.news,
    event: state.event,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNewsAction,
      getEventAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
