import React from 'react';
import NIHR from "../../../assets/images/NIHR.png";
import uk from "../../../assets/images/uk_aid.png";

const FundedBy = () => {
    return ( 
        <div className="fundedby-container">
            <div className="fundedby">
                <div className="fundedby-NIHR" style={{ backgroundImage: `url(${NIHR})` }}>
                </div>
                <div className="fundedby-uk" style={{ backgroundImage: `url(${uk})` }}>
                </div>
            </div>
        </div>
     );
}
 
export default FundedBy;