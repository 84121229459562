import React from "react";
import { useAuth } from "react-uicomp";
import { BsFileEarmarkText } from "react-icons/bs";

export const NoDataUI = ({ message }) => {
    const { language } = useAuth()

    return (
        <div className="noDataUI-container">
            <div className="noDataUI">
                <BsFileEarmarkText className="noDataUI-icon" />
                <div className="noDataUI-message">
                    {message ? message : language.key.nodata}
                </div>
            </div>
        </div>
    )
}