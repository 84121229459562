import React from "react";
import Slider from "react-slick";
import { useAuth, useNavigation } from "react-uicomp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// COMMONS
import Title from "../../common/title/Title.common";
import NewsCard from "../../common/newsCard/NewsCard.common";
import { NoDataUI } from "../../common/noDataUI/noDataUI.common";

export const News = ({ data }) => {
  const settings = {
    dots: true,
    initialSlide: 2,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { language } = useAuth();
  const { navigation } = useNavigation();
  const { routes } = navigation;
  return (
    <div className="newsupdate-container">
      <div className="newsupdate">
        <Title
          title={language.key.newsupdate}
          noBorder
          seeAllRoute={routes.News.path}
        />

        {data?.length ? <div className="newsupdate-layout" >
          <Slider {...settings}>
            {data.map((val, index) => {
              return <NewsCard key={index} data={val} gotoPage="News" />;
            })}
          </Slider>
        </div> : <NoDataUI message='No News Updates' />}
      </div>
    </div>
  );
};
