import { NEWSLETTER } from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
  detailLoader: false,
  detail: null,
};

export function newsletterReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case NEWSLETTER.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case NEWSLETTER.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case NEWSLETTER.ERROR:
      return {
        ...state,
        listLoader: false,
      };
    default:
      return state;
  }
}
