import React from "react";
import { AiFillEye, FaCloudDownloadAlt } from "react-icons/all";
import { useAuth } from "react-uicomp";
import { BASE_URL, FILE_URL } from "../../../config/Config";

import DateComponent from "../date/Date.component";
import SeeMore from "../seemore/Seemore.common";

const ArticleCard = ({ data }) => {
  const { language } = useAuth();
  const { thumbnail, description, pdf, created_at } = data;
  const { title, shortDesc } = description;

  return (
    <div className="articlecard-container">
      <div className="articlecard">
        <div
          className="articlecard-image"
          style={{
            backgroundImage: `url(${FILE_URL + thumbnail})`,
          }}></div>
        <div className="articlecard-info">
          <div className="articlecard-info-header">
            <div className="title">
              {language.isNepali ? title?.np : title?.en}
            </div>
            <div className="desc">
              {" "}
              {language.isNepali ? shortDesc?.np : shortDesc?.en}
            </div>
          </div>
          <div className="articlecard-info-footer">
            <div>
              <DateComponent date={created_at?.slice(0, 10)} />
            </div>
            <div className="articlecard-info-footer-seemore">
              <div onClick={() => window.open(FILE_URL + pdf)}>
                <SeeMore title={language.key.view} icon={<AiFillEye />} />
              </div>

              <div
                style={{ marginLeft: 16 }}
                onClick={() => window.open(BASE_URL + "/download?path=" + pdf)}>
                <SeeMore
                  title={language.key.download}
                  icon={<FaCloudDownloadAlt />}
                />
              </div>
            </div>
            <div className="articlecard-info-footer-seemore-mobile">
              <AiFillEye onClick={() => window.open(FILE_URL + pdf)} />
              <div
                style={{ marginLeft: 16 }}
                onClick={() => window.open(BASE_URL + "/download?path=" + pdf)}>
                <FaCloudDownloadAlt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
