import React from "react";
import { useAuth } from "react-uicomp";
import { BiUpArrowAlt, FaBullhorn, TiDelete } from "react-icons/all";

export const PrimaryButton = (props) => {
  const { title, ...rest } = props;

  return (
    <button className="button primary" {...rest}>
      {title}
    </button>
  );
};

export const SecondaryButton = (props) => {
  const { title, ...rest } = props;

  return (
    <button className="button secondary" {...rest}>
      {title}
    </button>
  );
};

export const GotoTopButton = (props) => {
  const { language } = useAuth();
  return (
    <button
      className="button gototop"
      {...props}
      onClick={() => window.scrollTo(0, 0)}>
      <span className="goto-title">{language.key.scrolltotop}</span>
      <span className="goto-icon">
        <BiUpArrowAlt size={24} />
      </span>
    </button>
  );
};

export const VacancyButton = (props) => {
  return (
    <button className="button vacancy" {...props}>
      <span className="title">Vacancy</span>
      <span className="icon">
        <FaBullhorn size={24} />
      </span>
    </button>
  );
};

export const CloseButton = (props) => {
  return (
    <button className="button close" {...props}>
      <span className="title">Close</span>
      <span className="icon">
        <TiDelete size={24} />
      </span>
    </button>
  );
};
