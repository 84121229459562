import React, { useEffect, useState } from "react";
import {
  Auth,
  withNavigation,
  AnimatedBlock,
  useScroll,
  useAnimatedValue,
  interpolate,
  useMeasure,
  useMountedValue,
  useWindowDimension,
  Toast,
  useToast,
} from "react-uicomp";
import { publicPaths, privatePaths } from "./Routes.app";
import { userRoles } from "./UserRoles.app";
import {
  englishLanguage,
  nepaliLanguage,
  LANGUAGE_TYPE,
} from "../../language/Config.language";
import { BASE_TITLE, MAIN_TITLE } from "../../constants/documentTitle.constant";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { vacancyAction } from "../../actions/Actions";

// COMMONS
import Header from "../common/header/Header.common";
import Footer from "../common/footer/Footer.common";
import Vacancy from "../common/vacancy/Vacancy.common";
import {
  GotoTopButton,
  VacancyButton,
  CloseButton,
} from "../common/button/Button.common.js";

// LOCAL KEY
const LANGUAGE_KEY = "@lang";

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ vacancyAction }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    vacancy: state.vacancy,
  };
};

export const FloatingButtons = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const { vacancyAction, animation, vacancy } = props;
  const { list } = vacancy;
  const { handler, width } = useMeasure();
  const { width: SCREEN_WIDTH } = useWindowDimension();
  const [modalVisible, setModalVisible] = useState(false);
  const mountedValue = useMountedValue(modalVisible, [0, 1, 0], {
    exitDuration: 100,
  });

  useEffect(() => {
    vacancyAction();
  }, [vacancyAction]);

  useEffect(() => {
    document.body.style.overflow = modalVisible ? "hidden" : "visible";
  }, [modalVisible]);

  const outputRangeVacancy = SCREEN_WIDTH < 768 ? [width, 0] : [width + 155, 0];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "fixed",
          bottom: 50,
          right: 20,
          zIndex: 10,
        }}>
        <AnimatedBlock
          {...handler}
          style={{
            position: "relative",
            left: interpolate(animation.value, [0, 1], outputRangeVacancy),
            zIndex: 1,
          }}>
          {list.length > 0 && (
            <VacancyButton onClick={() => setModalVisible(true)} />
          )}
        </AnimatedBlock>

        <div style={{ width: 10 }} />
        <AnimatedBlock
          style={{
            position: "relative",
            opacity: animation.value,
            bottom: interpolate(animation.value, [0, 1], [-100, 0]),
          }}>
          <GotoTopButton />
        </AnimatedBlock>
      </div>

      {mountedValue(
        (animation, mounted) =>
          mounted && (
            <AnimatedBlock
              style={{ opacity: animation.value }}
              className="vacancy-modal-container">
              <AnimatedBlock
                style={{
                  position: "relative",
                  transformOrigin: "80% 80%",
                  top: interpolate(animation.value, [0, 1], [100, 0]),
                  transform: interpolate(
                    animation.value,
                    [0, 1],
                    ["scale(0.9)", "scale(1)"],
                  ),
                }}
                className="vacancy-modal">
                <div className="vacancy-modal-button">
                  <CloseButton onClick={() => setModalVisible(false)} />
                </div>
                <Vacancy />
              </AnimatedBlock>
            </AnimatedBlock>
          ),
      )}
    </>
  );
});

// ROOT COMPONENT
const App = () => {
  const { handler, toast } = useToast();
  const [headerTitle, setHeaderTitle] = useState(null);
  const { scrollY } = useScroll();
  const scrollToTopAnimation = useAnimatedValue(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (scrollY > window.innerHeight / 2) {
      scrollToTopAnimation.value = 1;
    } else {
      scrollToTopAnimation.value = 0;
    }
  }, [scrollY, scrollToTopAnimation]);

  useEffect(() => {
    document.title =
      headerTitle === "Home" ? MAIN_TITLE : `${BASE_TITLE}${headerTitle}`;
  }, [headerTitle]);

  // FOR LANGUAGE
  const savedLang = localStorage.getItem(LANGUAGE_KEY) || LANGUAGE_TYPE.en;
  const [activeLanguage, setActiveLanguage] = useState(savedLang);
  const language =
    activeLanguage === LANGUAGE_TYPE.en ? englishLanguage : nepaliLanguage;

  const [researchProgram, setResearchProgram] = useState();

  const setEnglishLanguage = () => {
    localStorage.setItem(LANGUAGE_KEY, LANGUAGE_TYPE.en);
    setActiveLanguage(LANGUAGE_TYPE.en);
  };

  const setNepaliLanguage = () => {
    localStorage.setItem(LANGUAGE_KEY, LANGUAGE_TYPE.np);
    setActiveLanguage(LANGUAGE_TYPE.np);
  };

  return (
    <Auth.Provider
      config={{ isLoggedIn: false, userRole: "admin" }}
      state={{
        toast,
        setHeaderTitle,
        language,
        setEnglishLanguage,
        setNepaliLanguage,
        researchProgram,
        setResearchProgram,
        drawerOpen,
        setDrawerOpen,
      }}>
      <Header headerTitle={headerTitle} />
      <Auth.Screens />
      <Footer />
      <FloatingButtons animation={scrollToTopAnimation} />
      <Toast {...handler} errorColor="#ff4343" />
    </Auth.Provider>
  );
};

// WRAP WITH withNavigation HOC
export default withNavigation(App, {
  publicPaths,
  privatePaths,
  userRoles,
  routerType: "hash",
});
