import React from "react";
import { useAuth, useNavigation } from "react-uicomp";
import H1 from "../../../assets/gallery/h1.jpg";

export const Intro = () => {
  const { language } = useAuth();
  const { navigation } = useNavigation();
  const { navigate } = navigation;
  const { isNepali } = language;

  return (
    <div className="IntroCard-wrapper">
      <div className="container">
        <div className="card">
          <div className="left">
            <div className="title">{language.key.whoweare}</div>
            <div className="paragraph">
              <p>{language.key.homepage.introductionfull.para1}</p>
              <p>{language.key.homepage.introductionfull.para2}</p>
              {isNepali ? (
                <p>
                  NIRC अन्य बाह्यिक आर्थिक सहयोगमा अनुसन्धान अध्ययनहरू होस्ट
                  गर्दछ,
                  <br /> थप जानकारीको लागि अनुसन्धान कार्यक्रमहरू हेर्नुहोस्।
                </p>
              ) : (
                <p>
                  {/* The NIRC hosts other externally funded research studies,
                  please see Research Programme for more information. */}
                  The NIRC hosts other externally funded research studies,
                  please see Research Programme for more information.
                </p>
              )}
            </div>
          </div>
          <div className="right">
            <img src={H1} alt="introduction" />
          </div>
        </div>
      </div>
    </div>
  );
};
