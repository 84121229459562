import { EVENT, EVENT_DETAIL } from "./Actions";
import { api, APIS } from "../config/Config";

export function getEventAction(researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EVENT.LOADING });
      res = await api(`${APIS.event}${researchProgramId ? '&researchProgramId=' + researchProgramId : ''}`);

      const { success, data } = res.data;
      // console.log("recent event action", data);
      if (success === "true") {
        dispatch({ type: EVENT.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: EVENT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: EVENT.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getEventDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EVENT_DETAIL.LOADING });
      res = await api(`${APIS.common}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: EVENT_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: EVENT_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: EVENT_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}
