import { apiGenerator, getCookie } from "../helpers/Helpers";

const token = getCookie("token"); // GET TOKEN FROM COOKIE
// export const BASE_URL = "http://192.168.1.114:8849/api"; // BASE URL
export const BASE_URL = "https://api.nirc.org.np/api"; // BASE URL
export const FILE_URL = "https://api.nirc.org.np/docs/"; // BASE FILE URL

// API FUNCTION
export const api = apiGenerator({ token, baseURL: BASE_URL });

// API PATHS
export const APIS = {
  sample: "/sample",
  common: "/common",
  news: "/common/type?name=news&limit=1000",
  media: "/common/type?name=media&limit=1000",
  contact: "/common/contact",
  team: "/team/type?name=team&limit=1000",
  publication: "/common/type?name=publications&limit=1000",
  newsletter: "/common/type?name=newsletter&limit=1000",
  event: "/common/type?name=events&limit=1000",
  category: "/category",
  selectedCategory: "/common/type?name=research&limit=1000&order=1",
  partner: "/team/type?name=partner&limit=1000",
  vacancy: "/common/type?name=vacancy",
  researchProgram: "/research-program?page=1&limit=10",
};
