import { CONTACT } from "../actions/Actions";

const initalState = {
  addLoader: false,
};

export function contactReducer(state = initalState, action) {
  const { type } = action;

  switch (type) {
    //  ADD
    case CONTACT.LOADING:
      return {
        ...state,
        addLoader: true,
      };
    case CONTACT.SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case CONTACT.ERROR:
      return {
        ...state,
        addLoader: false,
      };

    default:
      return state;
  }
}
