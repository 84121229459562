import { RESEARCH_PROGRAM } from "../actions/ActionTypes.action";

const initialState = {
    listLoader: false,
    listCount: null,
    list: [],
};

export function researchProgramReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case RESEARCH_PROGRAM.LOADING:
            return {
                ...state,
                listLoader: true,
            };
        case RESEARCH_PROGRAM.SUCCESS:
            return {
                ...state,
                listLoader: false,
                listCount: payload.total,
                list: payload.rows,
            };
        case RESEARCH_PROGRAM.ERROR:
            return {
                ...state,
                listLoader: false,
            };
        default: return state
    }
}