import React from "react";
import { useAuth } from "react-uicomp";

export const Information = () => {
  const { language } = useAuth();
  return (
    <div className="Introduction-Information-wrapper">
      <div className="research">
        <div className="title">{language.key.introductionPage.whyisnirc}</div>
        <div className="paragraph">
          <p>{language.key.introductionPage.whynircdesc?.para1}<br /><br />{language.key.introductionPage.whynircdesc?.para2}</p>
        </div>
      </div>
      {/* <div className="research">
        <div className="title">{language.key.introductionPage.howfunded}</div>
        <div className="paragraph">
          <p>
            {language.key.introductionPage.howfundedDesc} <br />
            <br />
            {language.key.introductionPage.howfundedp1}
            <br />
            {language.key.introductionPage.howfundedp2}
            <br />
            {language.key.introductionPage.howfundedp3}
            <br />
          </p>
        </div>
      </div> */}
    </div>
  );
};
