import { NEWS, NEWS_DETAIL } from "./Actions";
import { api, APIS } from "../config/Config";

export function getNewsAction(researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: NEWS.LOADING });
      res = await api(`${APIS.news}${researchProgramId ? '&researchProgramId=' + researchProgramId : ''}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: NEWS.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: NEWS.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: NEWS.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getNewsDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: NEWS_DETAIL.LOADING });
      res = await api(`${APIS.common}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: NEWS_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: NEWS_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: NEWS_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}
