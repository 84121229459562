import React from "react";

export const TopCard = ({ leftCard, rightCard }) => {
  return (
    <div className="topcard-container">
      <div className="topcard">
        {leftCard}
        {rightCard}
      </div>
    </div>
  );
};
