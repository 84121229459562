// ROUTES
import HomePage from "../homePage/Homepage";
import IntroductionPage from "../introductionPage/IntroductionPage";
import OurTeamPage from "../ourTeamPage/OurTeamPage";
import OurResearchPage from "../ourResearchPage/OurResearchPage";
import ResearchDetailPage from "../researchDetailPage/ResearchDetailPage";
import ContactUsPage from "../contactUsPage/ContactUsPage";
import NotFoundPage from "../notFoundPage/NotFoundPage";
import EventsPage from "../eventsPage/EventsPage";
import NewsPage from "../newsPage/NewsPage";
import MediaOutputsPage from "../mediaOutputsPage/MediaOutputsPage";
import MediaOutputsDetailPage from "../mediaOutputsDetailPage/MediaOutputsDetailPage";
import NewsLetterPage from "../newsLetterPage/NewsLetterPage";
import PublicationPage from "../publicationPage/PublicationPage";
import NewsDetailPage from "../newsDetailPage/NewsDetailPage";
import EventsDetailPage from "../eventsDetailPage/EventsDetailPage";
import StudyFundedByPage from "../ourResearchPage/StudyFundedByPage";

export const publicPaths = [
  {
    name: "home",
    path: "/",
    component: HomePage,
    restricted: true,
  },
  {
    name: "introduction",
    path: "/introduction",
    component: IntroductionPage,
    restricted: true,
  },
  {
    name: "ourteam",
    path: "/our-team",
    component: OurTeamPage,
    restricted: true,
  },
  {
    name: "ResearchPage",
    path: "/research/:title",
    component: OurResearchPage,
    restricted: true,
  },
  {
    name: "StudyFundedPage",
    path: "/research",
    component: StudyFundedByPage,
    restricted: true,
  },
  {
    name: "ResearchDetail",
    path: "/research-detail/:id",
    component: ResearchDetailPage,
    restricted: true,
  },
  {
    name: "ContactUs",
    path: "/contact",
    component: ContactUsPage,
    restricted: true,
  },
  {
    name: "Events",
    path: "/events",
    component: EventsPage,
    restricted: true,
  },
  {
    name: "News",
    path: "/news",
    component: NewsPage,
    restricted: true,
  },
  {
    name: "Media Outputs",
    path: "/media-outputs",
    component: MediaOutputsPage,
    restricted: true,
  },
  {
    name: "Publication",
    path: "/publication",
    component: PublicationPage,
    restricted: true,
  },
  {
    name: "Newsletter",
    path: "/newsletter",
    component: NewsLetterPage,
    restricted: true,
  },
  {
    name: "NewsDetail",
    path: "/news/:id",
    component: NewsDetailPage,
    restricted: true,
  },
  {
    name: "EventsDetail",
    path: "/events/:id",
    component: EventsDetailPage,
    restricted: true,
  },
  {
    name: "EventsDetail",
    path: "/media-outputs/:id",
    component: MediaOutputsDetailPage,
    restricted: true,
  },
  {
    path: null,
    component: NotFoundPage,
  },
];

export const privatePaths = [];
