import React, { useEffect } from "react";
import { useAuth } from "react-uicomp";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ActivityIndicator from "../hoc/activityIndicator/ActivityIndicator.hoc";
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";
import { Team } from "./components";
import { NoDataUI } from "../common/noDataUI/noDataUI.common";

import { getTeamMemberAction } from "../../actions/Actions";
import { teamFormatter } from "./utils/ourTeamFomatter.util";

const OurTeamPage = (props) => {
  const { researchProgram, setHeaderTitle, language } = useAuth();
  const { teamMember, getTeamMemberAction } = props;
  const { listLoader, list } = teamMember;
  const teams = teamFormatter(list);

  useEffect(() => {
    setHeaderTitle(language.key.ourteam);
  }, [setHeaderTitle, language.key.ourteam]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getTeamMemberAction(researchProgram?.id);
  }, [getTeamMemberAction, researchProgram]);

  return (
    <CompWrapper>
      <ActivityIndicator animating={listLoader}>
        {list?.length > 0
          ? <Team teams={teams} />
          : <NoDataUI />}
      </ActivityIndicator>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    teamMember: state.teamMember,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getTeamMemberAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OurTeamPage);
