import { APIS, api } from "../config/Api.config";
import { RESEARCH_PROGRAM } from "./ActionTypes.action";

export function getResearchProgramAction(researchProgramStatus) {
    return async function (dispatch) {
        let res;
        try {
            dispatch({ type: RESEARCH_PROGRAM.LOADING });
            res = await api(`${APIS.researchProgram}${researchProgramStatus ? '&status=' + researchProgramStatus : ''}`);

            const { success, data } = res?.data;
            if (success === "true") {
                dispatch({ type: RESEARCH_PROGRAM.SUCCESS, payload: data.data });
            } else {
                dispatch({ type: RESEARCH_PROGRAM.ERROR });
            }
        } catch ({ message }) {
            dispatch({ type: RESEARCH_PROGRAM.ERROR })
            console.error(message)
            return 0;
        }
    }
}