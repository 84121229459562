import { createActionSet } from "../helpers/Helpers";

// SAMPLE ACTION SET
export const SAMPLE = createActionSet("SAMPLE");

// NEWS
export const NEWS = createActionSet("NEWS");
export const NEWS_DETAIL = createActionSet("NEWS_DETAIL");

// MEDIA OUTPUTS
export const MEDIA = createActionSet("MEDIA");
export const MEDIA_DETAIL = createActionSet("MEDIA_DETAIL");

// CONTACT
export const CONTACT = createActionSet("CONTACT");

//TEAMMEMBER ACTION SET
export const TEAMMEMBER = createActionSet("TEAMMEMBER");
//PUBLICATION ACTION SET
export const PUBLICATION = createActionSet("PUBLICATION");
export const PUBLICATION_DETAIL = createActionSet("PUBLICATION_DETAIL");

//NEWSLETTER ACTION SET
export const NEWSLETTER = createActionSet("NEWSLETTER");

//RESEARCH ACTION SET
export const RESEARCH = createActionSet("RESEARCH");
export const GET_RESEARCH_CATEGORY = createActionSet("GET_RESEARCH_CATEGORY");

//STUDY ACTION SET
export const STUDY_DETAIL = createActionSet("STUDY_DETAIL");
//COLLABORATOR ACTION SET
export const COLLABORATOR = createActionSet("COLLABORATOR");

//VACANCY ACTION SET
export const VACANCY = createActionSet("VACANCY");

//EVENT ACTION SET
export const EVENT = createActionSet("EVENT");
export const EVENT_DETAIL = createActionSet("EVENT_DETAIL");

//RESEARCH_PROGRAM ACTION SET
export const RESEARCH_PROGRAM = createActionSet("RESEARCH PROGRAM");