import { COLLABORATOR } from "./Actions";
import { api, APIS } from "../config/Config";

export function collaboratorAction(researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: COLLABORATOR.LOADING });
      res = await api(`${APIS.partner}${researchProgramId ? '&researchProgramId=' + researchProgramId : ''}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: COLLABORATOR.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: COLLABORATOR.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: COLLABORATOR.ERROR });
      console.error(message);
      return 0;
    }
  };
}
