import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-uicomp";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getResearchAction } from "../../actions/Actions";
import { getCurrentLanguage } from "../../utils/getCurrentLanguage.util";

import ActivityIndicator from "../hoc/activityIndicator/ActivityIndicator.hoc";
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";
import FundCard from "../common/card/Fundcard.common";
import { FILE_URL } from "../../config/Config";
import { NoDataUI } from "../common/noDataUI/noDataUI.common";

const StudyFundedByPage = (props) => {
  const { getResearchAction, research } = props;
  const { setHeaderTitle, language, researchProgram } = useAuth();
  const { navigation } = useNavigation();
  const { navigate } = navigation;
  const currentLanguage = getCurrentLanguage(language);
  const { listLoader, list } = research;

  useEffect(() => {
    setHeaderTitle(language.key.currentResearch);
  }, [setHeaderTitle, language.key.currentResearch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getResearchAction(researchProgram?.id);
  }, [getResearchAction, researchProgram]);

  return (
    <div>
      <CompWrapper>
        <div className="study">
          <ActivityIndicator animating={listLoader}>
            {list.length > 0 ?
              <div className="contents">
                <div className="contents-cards">
                  {list.map((item, index) => {
                    const id = item?.id;
                    const image = item?.cat_image;
                    const description = JSON.parse(item?.cat_description);
                    const title = description?.title?.[currentLanguage];

                    return (
                      <div key={index}>
                        <FundCard
                          onClick={() => navigate(`research/${id}`)}
                          title={title}
                          image={`${FILE_URL}/${image}`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              : <NoDataUI />}
          </ActivityIndicator>
        </div>
      </CompWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    research: state.research,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getResearchAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyFundedByPage);
