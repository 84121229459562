import { STUDY_DETAIL } from "../actions/Actions";

const initalState = {
  detail: null,
  detailLoader: false,
};

export function studyReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET DETAIL
    case STUDY_DETAIL.LOADING:
      return {
        ...state,
        detailLoader: true,
        detail: null,
      };
    case STUDY_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detail: payload,
      };
    case STUDY_DETAIL.ERROR:
      return {
        ...state,
        detailLoader: false,
      };

    default:
      return state;
  }
}
