import React from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "react-uicomp";
import { SecondaryButton } from "../../common/button/Button.common";
import Title from "../../common/title/Title.common";
import ActivityIndicator from "../../hoc/activityIndicator/ActivityIndicator.hoc";

export const ContactForm = (props) => {
  const { language, toast } = useAuth();
  const { handleSubmit, register, reset } = useForm();
  const { postContactAction, contact } = props;
  const { addLoader } = contact;

  const onSubmit = (data) => {
    const description = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      subject: data.subject,
      message: data.message,
    };

    const formdata = new FormData();

    formdata.append("description", JSON.stringify(description));
    formdata.append("type", "contact");

    const body = {
      description,
      type: "contact",
    };

    postContactAction(body, resetHandler, toast);
  };

  const resetHandler = () => {
    reset();
  };

  return (
    <div className="contact-form-wrapper">
      <div className="contact-form-container">
        <Title title={language.key.contactpage.writetous} hide_SeeAll />
        <div>
          <form className="contact-form-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="contact-details">
              <div className="contact-name">
                <label>
                  {language.key.contactpage.name}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder={language.key.contactpage.namePlaceholder}
                  ref={register({ required: true })}
                />
              </div>
              <div className="contact-phone">
                <label>
                  {language.key.contactpage.phone}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  placeholder={language.key.contactpage.phonePlaceholder}
                  ref={register({ required: true })}
                />
              </div>
            </div>

            <div className="contact-email">
              <label>
                {language.key.contactpage.email}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder={language.key.contactpage.emailPlaceholder}
                ref={register({ required: true })}
              />
            </div>
            <div className="contact-subject">
              <label>
                {language.key.contactpage.subject}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="subject"
                placeholder={language.key.contactpage.subject}
                ref={register({ required: true })}
              />
            </div>
            <div className="contact-message">
              <label>
                {language.key.contactpage.message}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                name="message"
                rows="5"
                placeholder={language.key.contactpage.messagePlaceholder}
                ref={register({ required: true })}
              />
            </div>
            <div className="contact-button">
              <ActivityIndicator animating={addLoader}>
                <SecondaryButton
                  title={language.key.contactpage.send}
                  type="submit"
                />
              </ActivityIndicator>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
