import { VACANCY } from "../actions/Actions";

const initalState = {
  loading: false,
  error: false,
  list: [],
};

export function vacancyReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case VACANCY.LOADING:
      return { ...state, loading: true, error: false };
    case VACANCY.SUCCESS:
      return { ...state, loading: false, error: false, list: payload.rows };
    case VACANCY.ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
