import { combineReducers } from "redux";
import { sampleReducer } from "./Sample.reducer";
import { newsReducer } from "./News.reducer";
import { mediaReducer } from "./Media.reducer";
import { contactReducer } from "./Contact.reducer";
import { teammemberReducer } from "./TeamMember.reducer";
import { publicationReducer } from "./Publication.reducer";
import { newsletterReducer } from "./Newsletter.reducer";
import { collaboratorReducer } from "./Collaborator.reducer";
import { researchReducer } from "./Research.reducer";
import { vacancyReducer } from "./Vacancy.reducer";
import { eventReducer } from "./Event.reducer";
import { studyReducer } from "./Study.reducer";
import { researchProgramReducer } from "./ResearchProgram.reducer";

const rootReducer = combineReducers({
  samples: sampleReducer,
  news: newsReducer,
  media: mediaReducer,
  contact: contactReducer,
  teamMember: teammemberReducer,
  publication: publicationReducer,
  newsletter: newsletterReducer,
  collaborator: collaboratorReducer,
  research: researchReducer,
  vacancy: vacancyReducer,
  event: eventReducer,
  study: studyReducer,
  researchProgram: researchProgramReducer,
});

export default rootReducer;
