import React from "react";
import { connect } from "react-redux";
import HTMLParser from "react-html-parser";

const Vacancy = (props) => {
  const { vacancy } = props;
  const { list } = vacancy;

  // console.log("vacansylist", list);
  return (
    <div className="vacancy-container">
      {list.map((items, index) => {
        const description = items?.description.desc;
        const title = items?.title;

        return (
          <div className="vacancy" key={index}>
            <div className="vacancy-title">{title}</div>
            <div className="vacancy-section"></div>
            <div className="vacancy-section">
              <div className="vacancy-section-title">About the job</div>

              <div className="vacancy-section-body">
                {HTMLParser(description)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vacancy: state.vacancy,
  };
};

export default connect(mapStateToProps, null)(Vacancy);
