import React from "react";

const FundCard = ({ title, image, onClick }) => {
  return (
    <div className="fund" onClick={onClick}>
      <div
        className="fund-image"
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: "center",
        }}></div>
      <div className="fund-title">{title}</div>
    </div>
  );
};

export default FundCard;
