import React from "react";

const CompWrapper = ({ children }) => {
  return (
    <div className="compwrapper-container">
      <div className="compwrapper">
        {/* <div className="compwrapper-header">
      <div className="compwrapper-header-title">
            {header}
      </div>
      </div> */}
        <div className="compwrapper-content">{children}</div>
      </div>
    </div>
  );
};

export default CompWrapper;
