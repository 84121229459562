import React from "react";
import { FaRegCalendar } from "react-icons/all";

const DateComponent = ({date}) => {
  return (
    <div className="date-container">
      <span>
        <FaRegCalendar />
      </span>
      <div className="date">{date}</div>
    </div>
  );
};

export default DateComponent;