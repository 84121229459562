import { RESEARCH, GET_RESEARCH_CATEGORY } from "./Actions";
import { api, APIS } from "../config/Config";

export function getResearchAction(researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: RESEARCH.LOADING });
      res = await api(`${APIS.category}/all${researchProgramId ? '?researchProgramId=' + researchProgramId : ''}`);

      const { success, data } = res?.data;
      if (success === "true") {
        dispatch({ type: RESEARCH.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: RESEARCH.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: RESEARCH.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getSelectedCategoryAction(catId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_RESEARCH_CATEGORY.LOADING });
      res = await api(`${APIS.selectedCategory}&cat=${catId}`);

      const { success, data } = res?.data;
      if (success === "true") {
        dispatch({ type: GET_RESEARCH_CATEGORY.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GET_RESEARCH_CATEGORY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_RESEARCH_CATEGORY.ERROR });
      console.error(message);
      return 0;
    }
  };
}
