import { MEDIA, MEDIA_DETAIL } from "./Actions";
import { api, APIS } from "../config/Config";

export function getMediaAction(researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: MEDIA.LOADING });
      res = await api(`${APIS.media}${researchProgramId ? '&researchProgramId=' + researchProgramId : ''}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: MEDIA.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: MEDIA.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: MEDIA.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getMediaDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: MEDIA_DETAIL.LOADING });
      res = await api(`${APIS.common}/${id}`);

      const { success, data } = res.data;

      if (success === "true") {
        dispatch({ type: MEDIA_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: MEDIA_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: MEDIA_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}
