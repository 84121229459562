import React from "react";
import { useAuth, useNavigation } from "react-uicomp";

import { FILE_URL } from "../../../config/Config";
import { getCurrentLanguage } from "../../../utils/getCurrentLanguage.util";

import IMAGE from "../../../assets/images/bg_1.jpg";
import DateComponent from "../date/Date.component";
import { SeeMoreRes } from "../seemore/Seemore.common";

const NewsCard = ({ data, gotoPage }) => {
  // console.log(data);
  const { id, thumbnail, description, created_at } = data;
  const { title, shortDesc } = description;
  const { language } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;

  const currentLanguage = getCurrentLanguage(language);

  return (
    <div className="newscard-container">
      <div className="newscard">
        <div
          className="newscard-image"
          style={{ backgroundImage: `url(${FILE_URL + thumbnail})` }}></div>
        <div className="newscard-info">
          <div className="newscard-info-header">
            <div className="title">{title?.[currentLanguage]}</div>
            <div className="desc">{shortDesc?.[currentLanguage]}</div>
          </div>
          <div className="newscard-info-footer">
            <DateComponent date={created_at?.slice(0, 10)} />
            <div
              className="newscard-info-footer-seemore"
              onClick={() => {
                navigate(routes[gotoPage].path + `/${id}`);
              }}>
              <SeeMoreRes title={language.key.readmore} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;

NewsCard.defaultProps = {
  image: IMAGE,
  date: "29 June,2020",
  title:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  description:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
};
