import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import Slider from "react-slick";
import { useAuth } from "react-uicomp";

// COMMONS
import Title from "../../common/title/Title.common";
import CollaboratorCard from "../../common/collaboratorCard/CollaboratorCard.common";

import { collaboratorAction } from "../../../actions/Actions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NoDataUI } from "../../common/noDataUI/noDataUI.common";
import ActivityIndicator from "../../hoc/activityIndicator/ActivityIndicator.hoc";

const CollaboratorsComponent = (props) => {
  const { collaboratorAction, collaborator } = props;
  const collaboratorsList = collaborator?.list?.rows;
  const { language, researchProgram } = useAuth();
  const { loading } = useSelector((state) => state.collaborator);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: collaboratorsList?.length < 4 ? collaboratorsList?.length : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    collaboratorAction(researchProgram?.id);
  }, [collaboratorAction, researchProgram]);

  return (
    <div className="collaborators-container">
      <div className="collaborators">
        <Title title={language.key.collaborators} noBorder hide_SeeAll />
        <ActivityIndicator animating={loading}>
          {collaboratorsList?.length > 0 ? (
            <div className="collaborators-layout">
              <Slider {...settings}>
                {collaboratorsList?.map((item, index) => {
                  return <CollaboratorCard key={index} data={item} />;
                })}
              </Slider>
            </div>
          ) : (
            <NoDataUI message="No Collaborators Available" />
          )}
        </ActivityIndicator>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    collaborator: state.collaborator,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ collaboratorAction }, dispatch);
};

export const Collaborators = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollaboratorsComponent);
