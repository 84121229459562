import { TEAMMEMBER } from "../actions/Actions";

const initalState = {
  listLoader: false,
  listCount: null,
  list: [],
};

export function teammemberReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    // GET
    case TEAMMEMBER.LOADING:
      return {
        ...state,
        listLoader: true,
      };
    case TEAMMEMBER.SUCCESS:
      return {
        ...state,
        listLoader: false,
        listCount: payload.total,
        list: payload.rows,
      };
    case TEAMMEMBER.ERROR:
      return {
        ...state,
        listLoader: false,
      };

    default:
      return state;
  }
}
