import React from "react";

const SubTitle = ({ title, description }) => {
  return (
    <div className="subtitle">
      <div className="subtitle-title">
        {title === "link" ? (
          <a href={description} target="_blank" rel="noopener noreferrer">
            {description}
          </a>
        ) : (
          <>
            {`${title}: `}
            <span>{description}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default SubTitle;
