import React, { useEffect } from "react";
import { useAuth } from "react-uicomp";
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";
import MoreEvents from "./components/MoreEvents.component";
import RecentEvents from "./components/RecentEvents.component";
import ActivityIndicator from "../hoc/activityIndicator/ActivityIndicator.hoc";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getEventAction } from "../../actions/Actions";
import { NoDataUI } from "../common/noDataUI/noDataUI.common";

const EventsPage = (props) => {
  const { setHeaderTitle, language, researchProgram } = useAuth();
  const { getEventAction, event } = props;
  const { listLoader, list } = event;

  useEffect(() => {
    setHeaderTitle(language.key.events);
  }, [setHeaderTitle, language.key.events]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getEventAction(researchProgram?.id);
  }, [getEventAction, researchProgram]);

  return (
    <CompWrapper>
      <ActivityIndicator animating={listLoader}>
        {list.length > 0 ? (
          <>
            <RecentEvents data={list} />
            <MoreEvents data={list} />
          </>
        ) : <NoDataUI />}
      </ActivityIndicator>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    event: state.event,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEventAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
