export const MEMBER_TYPES = {
  "The Directors": { np: "निर्देशकहरु", en: "The Directors" },
  "Research Team": { np: "अन्वेषकहरू", en: "Research Team" },
  "UK Researcher Advisors": {
    np: "बेलायतका अन्वेषक सल्लाहकारहरू",
    en: "UK Researcher Advisors",
  },
  "Collaborators In Nepal": {
    np: "नेपालका सहयोगीहरू",
    en: "Collaborators In Nepal",
  },
  "International Collaborators": {
    np: "अन्तर्राष्ट्रिय सहयोगीहरू",
    en: "International Collaborators",
  },
};
