import { NEWSLETTER } from "./Actions";
import { api, APIS } from "../config/Config";

export function getNewsletterAction(researchProgramId) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: NEWSLETTER.LOADING });
      res = await api(`${APIS.newsletter}${researchProgramId ? '&researchProgramId=' + researchProgramId : ''}`);

      const { success, data } = res.data;
      if (success === "true") {
        dispatch({ type: NEWSLETTER.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: NEWSLETTER.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: NEWSLETTER.ERROR });
      console.error(message);
      return 0;
    }
  };
}

// export function getPublicationDetailAction(id) {
//   return async function (dispatch) {
//     let res;
//     try {
//       dispatch({ type: PUBLICATION_DETAIL.LOADING });
//       res = await api(`${APIS.common}/${id}`);

//       const { success, data } = res.data;

//       if (success === "true") {
//         dispatch({ type: PUBLICATION_DETAIL.SUCCESS, payload: data.data });
//       } else {
//         dispatch({ type: PUBLICATION_DETAIL.ERROR });
//       }
//     } catch ({ message }) {
//       dispatch({ type: PUBLICATION_DETAIL.ERROR });
//       console.error(message);
//       return 0;
//     }
//   };
// }
