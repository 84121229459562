import React, { useEffect, useState } from "react";
import {
  useAnimatedValue,
  AnimatedBlock,
  interpolate,
  useAuth,
  useNavigation,
} from "react-uicomp";

// COMMONS
import ActiveIndex from "../../common/activeIndex/ActiveIndex.common";
import { PrimaryButton } from "../../common/button/Button.common";

import BackgroundImage from "../../../assets/images/bg_1.jpg";
import BackgroundImage2 from "../../../assets/images/bg_2.jpg";
import BackgroundImage3 from "../../../assets/images/bg_3.jpg";

const SLIDER_IMAGES = [
  { image: BackgroundImage },
  { image: BackgroundImage2 },
  { image: BackgroundImage3 },
];

export const MainCarousel = () => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const animation = useAnimatedValue(activeImageIndex);
  const { language } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;

  useEffect(() => {
    const interval = setInterval(function () {
      setActiveImageIndex((prev) => (prev + 1) % 3);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel-container">
      <div className="activeIndicator-container">
        <ActiveIndex
          length={SLIDER_IMAGES.length}
          activeIndex={activeImageIndex}
          setActiveIndex={setActiveImageIndex}
        />
      </div>

      <div className="carousel-text">
        <div className="main">
          <h1>{language.key.homepage.firsth1}</h1>
          <h1>{language.key.homepage.secondh1}</h1>
        </div>

        <div className="secondary">
          <p>{language.key.homepage.introduction}</p>
        </div>

        <div className="carousel-text-button">
          <PrimaryButton
            title={language.key.readmore}
            onClick={() => navigate(routes.introduction.path)}
          />
        </div>
      </div>

      {/* IMAGE SLIDER */}
      <div style={{ overflow: "hidden" }}>
        <AnimatedBlock
          className="image-slider-container"
          style={{
            left: interpolate(
              animation.value,
              SLIDER_IMAGES.map((_, i) => i),
              SLIDER_IMAGES.map((_, i) => -1 * i * 100 + "%"),
            ),
          }}>
          {SLIDER_IMAGES.map((val, index) => (
            <div
              key={index}
              className="image-slider"
              style={{
                backgroundImage: `url(${val.image})`,
                backgroundPosition: "center",
              }}
            />
          ))}
        </AnimatedBlock>
      </div>

      <div className="overlay" />
    </div>
  );
};
