import React, { useState, useRef } from "react";
import {
    useOutsideClick,
    useMountedValue,
    AnimatedBlock,
    interpolate,
    useAuth,
    useNavigation,
} from "react-uicomp";
import { FaCaretDown } from "react-icons/all";
import { useSelector } from "react-redux";

export const ResearchProgramDropdown = () => {
    const { navigation } = useNavigation()
    const { navigate } = navigation;
    const dropdownRef = useRef(null);
    const { researchProgram, setResearchProgram, language } = useAuth();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const researchProgramsList = useSelector(
        (state) => state.researchProgram.list,
    );
    const mountedValue = useMountedValue(dropdownVisible, [0, 1, 0]);
    const { isNepali } = language;

    useOutsideClick(dropdownRef, function () {
        setDropdownVisible(false);
    });

    const outputRange = [
        "scale(0.8, 0.6) translateX(50%)",
        "scale(1, 1) translateX(50%)",
    ];

    const changeResearchProgram = (researchProgram) => {
        setResearchProgram((prev) => prev === researchProgram ? undefined : researchProgram)
        navigate('/research')
    }

    return (
        <div ref={dropdownRef} style={{ position: "relative" }}>
            <button
                className="researchProgramDropdown-title"
                onClick={() => setDropdownVisible((prev) => !prev)}>
                <span>{isNepali ? "अनुसन्धान कार्यक्रमहरू" : "Research Programme"}</span>
                <span
                    className="researchProgramDropdown-title-down"
                    style={{
                        transformOrigin: "55% 45%",
                        transform: dropdownVisible ? "rotate(180deg)" : "rotate(0deg)",
                    }}>
                    <FaCaretDown size={18} />
                </span>
            </button>
            {mountedValue(
                (animation, visible) =>
                    visible && (
                        <AnimatedBlock
                            className="researchProgramDropdown"
                            style={{
                                transformOrigin: "80% 0%",
                                marginTop: 10,
                                right: 0,
                                opacity: animation.value,
                                transform: interpolate(animation.value, [0, 1], outputRange),
                            }}>
                            <ul
                                className="researchProgramDropdown-links"
                                onClick={() => setDropdownVisible(false)}>
                                {researchProgramsList?.map((item, i) => (
                                    <li
                                        key={i}
                                        className={`researchProgramDropdown-links-item ${researchProgram === item ? "active" : ""}`}
                                        onClick={() => changeResearchProgram(item)}>
                                        {item.name[isNepali ? "np" : "en"]}
                                    </li>
                                ))}
                            </ul>
                        </AnimatedBlock>
                    ),
            )}
        </div>
    );
};