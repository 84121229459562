import React from "react";
import { useAuth } from "react-uicomp";
import { AiOutlineFile } from "react-icons/all";

import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";

const NotFoundPage = () => {
  const { language } = useAuth();
  return (
    <CompWrapper header="404 Page Not found">
      <div className="notfound-container">
        <div className="notfound">
          <AiOutlineFile size={80} />
          <div className="notfound-text">
            <div className="notfound-text-big">{language.key.notfound}!!!</div>
            <div className="notfound-text-small">
              {language.key.notfounddesc}{" "}
            </div>
          </div>
        </div>
      </div>
    </CompWrapper>
  );
};

export default NotFoundPage;
