import React, { useEffect } from "react";
import { useAuth } from "react-uicomp";
import NewsCard from "../common/newsCard/NewsCard.common";
import Title from "../common/title/Title.common";
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";
import ActivityIndicator from "../hoc/activityIndicator/ActivityIndicator.hoc";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getNewsAction } from "../../actions/Actions";
import { NoDataUI } from "../common/noDataUI/noDataUI.common";

const NewsPage = (props) => {
  const { setHeaderTitle, language, researchProgram } = useAuth();
  const { getNewsAction, news } = props;
  const { listLoader, list } = news;

  useEffect(() => {
    setHeaderTitle(language.key.news);
  }, [setHeaderTitle, language.key.news]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getNewsAction(researchProgram?.id);
  }, [getNewsAction, researchProgram]);

  return (
    <CompWrapper header="News">
      <Title title={language.key.news} hide_SeeAll />
      <ActivityIndicator animating={listLoader}>
        {list.length > 0 ? (
          <div className="news-container">
            {list.map((val, index) => {
              return (
                <div key={index}>
                  <NewsCard gotoPage="Media Outputs" data={val} />
                </div>
              );
            })}
          </div>
        ) : <NoDataUI />}
      </ActivityIndicator>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    news: state.news,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNewsAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
