import React, { useEffect, useState } from "react";
import {
  useNavigation,
  useScroll,
  interpolate,
  AnimatedBlock,
  useMeasure,
  useAuth,
  useAnimatedValue,
  useMountedValue,
} from "react-uicomp";
import {
  MdCall,
  MdEmail,
  HiMenu,
  FaNewspaper,
  FaRegBuilding,
  IoIosPeople,
  FaBookOpen,
  AiOutlineMail,
  FaRegImages,
  MdClose,
  FaCaretDown,
} from "react-icons/all";

import { ResearchProgramDropdown } from "../../researchProgram/ResearchProgram.component";
import HeaderDropdown from "../headerDropdown/HeaderDropdown.common";
import LanguageDropdown from "../languageDropdown/LanguageDropdown.common";
import LOGO from "../../../assets/images/logo.png";
import H1 from "../../../assets/gallery/h1.jpg";
import H2 from "../../../assets/gallery/h2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getResearchProgramAction } from "../../../actions/ResearchProgram.action";

const MobileDropdown = ({ title, subMenu, onClick, isResearchProgram }) => {
  const [dropdownExpanded, setDropdownExpanded] = useState(false);
  const { handler, height } = useMeasure();
  const animatedValue = useAnimatedValue(dropdownExpanded ? height : 0);
  const { language, researchProgram, setDrawerOpen } = useAuth();
  const { isNepali } = language;

  return (
    <div
      className="mobiledropdown"
      onClick={
        subMenu
          ? () => setDropdownExpanded((prev) => !prev)
          : onClick
          ? () => {
              onClick();
              setDrawerOpen(false);
            }
          : () => false
      }>
      <div className="mobiledropdown-title">
        <span>{title}</span>
        {subMenu && (
          <span
            className="icon-down"
            style={{
              transformOrigin: "55% 45%",
              transform: dropdownExpanded ? "rotate(180deg)" : "rotate(0deg)",
            }}>
            <FaCaretDown size={18} />
          </span>
        )}
      </div>

      <AnimatedBlock
        style={{
          height: animatedValue.value,
          overflow: "hidden",
        }}>
        <ul {...handler} className="mobiledropdown-list">
          {subMenu &&
            subMenu.map(({ title, icon, onClick }, index) => (
              <li
                className={`mobiledropdown-list-item ${
                  isResearchProgram ? "researchProgram" : ""
                } 
                ${
                  researchProgram?.name[isNepali ? "np" : "en"] === title
                    ? "active"
                    : ""
                }`}
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick && onClick();
                  setDrawerOpen(false);
                }}>
                {icon && <span className="list-item-icon">{icon}</span>}
                <span className="list-item-title">{title}</span>
              </li>
            ))}
        </ul>
      </AnimatedBlock>
    </div>
  );
};

const MobileDrawer = ({ drawerOpen, setDrawerOpen }) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { language, setResearchProgram } = useAuth();
  const { isNepali } = language;
  const mountedValue = useMountedValue(drawerOpen, [0, 1, 0]);
  const researchProgramsList = useSelector(
    (state) => state.researchProgram.list,
  );

  return (
    <>
      {mountedValue(
        (animation, mounted) =>
          mounted && (
            <AnimatedBlock
              className="mobiledrawer-container"
              style={{
                opacity: animation.value,
              }}>
              <div className="mobiledrawer-close">
                <div
                  className="close-button"
                  onClick={() => setDrawerOpen(false)}>
                  <MdClose size={29} />
                </div>
              </div>

              <AnimatedBlock
                style={{
                  transform: interpolate(
                    animation.value,
                    [0, 1],
                    ["translateX(10%)", "translateX(0%)"],
                  ),
                }}>
                <ul className="mobiledrawer">
                  <li className="mobiledrawer-item">
                    <MobileDropdown
                      title={language.key.home}
                      onClick={() => navigate(routes.home.path)}
                    />
                  </li>
                  <li className="mobiledrawer-item">
                    <MobileDropdown
                      title={language.key.whoweare}
                      subMenu={[
                        {
                          title: language.key.introduction,
                          icon: <FaRegBuilding size={18} />,
                          onClick: () => navigate(routes.introduction.path),
                        },
                        {
                          title: language.key.ourteam,
                          icon: <IoIosPeople size={24} />,
                          onClick: () => navigate(routes.ourteam.path),
                        },
                      ]}
                    />
                  </li>
                  <li className="mobiledrawer-item">
                    <MobileDropdown
                      title={language.key.researchPrograms}
                      subMenu={researchProgramsList.map((program) => ({
                        title: program.name[isNepali ? "np" : "en"],
                        onClick: () =>
                          setResearchProgram((prev) =>
                            prev === program ? undefined : program,
                          ),
                      }))}
                      isResearchProgram
                    />
                  </li>
                  <li className="mobiledrawer-item">
                    <MobileDropdown
                      title={language.key.events}
                      onClick={() => navigate(routes.Events.path)}
                    />
                  </li>
                  <li className="mobiledrawer-item">
                    <MobileDropdown
                      title={language.key.outputs}
                      subMenu={[
                        {
                          title: language.key.publication,
                          icon: <FaBookOpen size={18} />,
                          onClick: () => navigate(routes.Publication.path),
                        },
                        {
                          title: language.key.mediaOutput,
                          icon: <FaRegImages size={18} />,
                          onClick: () => navigate(routes.Articles.path),
                        },
                        {
                          title: language.key.newsletter,
                          icon: <AiOutlineMail size={18} />,
                          onClick: () => navigate(routes.Newsletter.path),
                        },
                        {
                          title: language.key.news,
                          icon: <FaNewspaper size={18} />,
                          onClick: () => navigate(routes.News.path),
                        },
                      ]}
                    />
                  </li>
                  <li className="mobiledrawer-item">
                    <MobileDropdown
                      title={language.key.contact}
                      onClick={() => navigate(routes.ContactUs.path)}
                    />
                  </li>
                </ul>
              </AnimatedBlock>
            </AnimatedBlock>
          ),
      )}
    </>
  );
};

const Header = ({ headerTitle }) => {
  const dispatch = useDispatch();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { scrollY } = useScroll();
  const scrollYAnimation = useAnimatedValue(scrollY);
  const { handler, height } = useMeasure();
  const { language, drawerOpen, researchProgram, setDrawerOpen } = useAuth();
  const { isNepali } = language;

  const [researchName, setResearchName] = useState("");

  useEffect(() => {
    // if (window.innerWidth < 535) {
    //   if (researchProgram) {
    //   }
    //   if (isNepali) {
    //     // setResearchName("सबै कार्यक्रम");
    //   } else {
    //     // setResearchName("All Programme");
    //   }
    // } else {
    if (researchProgram) {
      setResearchName(researchProgram?.name[isNepali ? "np" : "en"]);
    } else {
      setResearchName(language.key.allProgramme);
    }
    // }
  }, [window.innerWidth, isNepali, researchProgram]);

  useEffect(() => {
    dispatch(getResearchProgramAction());
  }, [dispatch]);

  return (
    <>
      <AnimatedBlock
        className="header-container"
        style={{
          top: interpolate(scrollYAnimation.value, [0, 200], [0, -height], {
            extrapolate: "clamp",
          }),
          paddingBottom: interpolate(
            scrollYAnimation.value,
            [0, 200],
            ["20px", "10px"],
            {
              extrapolate: "clamp",
            },
          ),
          boxShadow: interpolate(
            scrollYAnimation.value,
            [0, height],
            ["0px 0px 0px rgba(0,0,0,0)", "0px 4px 20px rgba(0,0,0,0.2)"],
            {
              extrapolate: "clamp",
            },
          ),
        }}>
        <div className="header-wrapper">
          <div {...handler} className="info">
            <div
              className="research-program"
              style={{
                visibility: window.innerWidth < 1500 ? "visible" : "hidden",
              }}>
              {researchName}
            </div>
            <div className="contact">
              <div className="contact-info">
                <span className="contact-info-icon">
                  <MdEmail size={20} />
                </span>

                <span className="contact-info-text">nirc@kmc.edu.np</span>
              </div>

              <div className="contact-info">
                <span className="contact-info-icon">
                  <MdCall size={20} />
                </span>

                <span className="contact-info-text">
                  {language.key.telephone}
                </span>
              </div>

              <div className="contact-info">
                <LanguageDropdown />
              </div>
            </div>
          </div>

          <div className="navigation">
            <div className="logo" onClick={() => navigate(routes.home.path)}>
              <img src={LOGO} alt="NIRC" />
              <div
                className="research-program"
                style={{
                  display:
                    window.innerWidth < 1500 && window.innerWidth > 767
                      ? "none"
                      : "block",

                  fontSize: window.innerWidth < 535 && "10px",
                  minWidth: window.innerWidth < 535 && "100px",
                }}>
                {researchName}
              </div>
            </div>

            <div className="navs">
              <ul style={{ alignItems: "center" }}>
                <li onClick={() => navigate(routes.home.path)}>
                  {language.key.home}
                </li>
                <li>
                  <HeaderDropdown
                    title={language.key.whoweare}
                    path={[
                      {
                        name: language.key.introduction,
                        icon: <FaRegBuilding size={18} />,
                        path: routes.introduction.path,
                      },
                      {
                        name: language.key.ourteam,
                        icon: <IoIosPeople size={24} />,
                        path: routes.ourteam.path,
                      },
                    ]}
                    headerImage={H1}
                    headerTitle={language.key.ourteam}
                    headerDesc={language.key.headerTeamDesc}
                  />
                </li>
                <li>
                  <ResearchProgramDropdown />
                </li>
                {/* <li onClick={() => navigate(routes.StudyFundedPage.path)}>
                  {language.key.currentResearch}
                </li> */}
                <li onClick={() => navigate(routes.Events.path)}>
                  {language.key.events}
                </li>
                <li>
                  <HeaderDropdown
                    title={language.key.outputs}
                    isBoundaryExtreme
                    path={[
                      {
                        name: language.key.publication,
                        icon: <FaBookOpen size={18} />,
                        path: routes.Publication.path,
                      },
                      {
                        name: language.key.mediaOutput,
                        icon: <FaRegImages size={18} />,
                        path: routes["Media Outputs"].path,
                      },
                      {
                        name: language.key.newsletter,
                        icon: <AiOutlineMail size={18} />,
                        path: routes.Newsletter.path,
                      },
                      {
                        name: language.key.news,
                        icon: <FaNewspaper size={18} />,
                        path: routes.News.path,
                      },
                    ]}
                    headerImage={H2}
                  />
                </li>
                <li onClick={() => navigate(routes.ContactUs.path)}>
                  {language.key.contact}
                </li>
              </ul>

              <div className="navs-lang">
                <LanguageDropdown />
              </div>

              <div className="navs-menu" onClick={() => setDrawerOpen(true)}>
                <HiMenu size={29} />
              </div>
            </div>
          </div>

          {headerTitle !== language.key.home &&
            headerTitle !== language.key.introduction && (
              <AnimatedBlock
                className="heading"
                style={{
                  height: interpolate(
                    scrollYAnimation.value,
                    [0, 200],
                    [60, 0],
                    {
                      extrapolate: "clamp",
                    },
                  ),
                }}>
                <AnimatedBlock
                  className="heading-title"
                  style={{
                    opacity: interpolate(
                      scrollYAnimation.value,
                      [0, 100],
                      [1, 0],
                      {
                        extrapolate: "clamp",
                      },
                    ),
                  }}>
                  {headerTitle}
                </AnimatedBlock>
              </AnimatedBlock>
            )}
        </div>
      </AnimatedBlock>
      <MobileDrawer {...{ drawerOpen, setDrawerOpen }} />
    </>
  );
};

export default Header;
