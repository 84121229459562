import React, { useState, useRef } from "react";
import {
  useOutsideClick,
  useMountedValue,
  AnimatedBlock,
  interpolate,
  useNavigation,
} from "react-uicomp";
import { FaCaretDown } from "react-icons/all";

const HeaderDropdown = (props) => {
  const { navigation } = useNavigation();
  const { navigate } = navigation;
  const {
    title,
    path,
    isBoundaryExtreme,
    headerImage,
    headerTitle,
    headerDesc,
  } = props;
  const dropdownRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const mountedValue = useMountedValue(dropdownVisible, [0, 1, 0]);

  useOutsideClick(dropdownRef, function () {
    setDropdownVisible(false);
  });

  const outputRange = isBoundaryExtreme
    ? ["scale(0.8, 0.6) translateX(20%)", "scale(1, 1) translateX(20%)"]
    : ["scale(0.8, 0.6) translateX(50%)", "scale(1, 1) translateX(50%)"];

  return (
    <div ref={dropdownRef} style={{ position: "relative" }}>
      <button
        className="headerdropdown-title"
        onClick={() => setDropdownVisible((prev) => !prev)}>
        <span>{title}</span>
        <span
          className="headerdropdown-title-down"
          style={{
            transformOrigin: "55% 45%",
            transform: dropdownVisible ? "rotate(180deg)" : "rotate(0deg)",
          }}>
          <FaCaretDown size={18} />
        </span>
      </button>
      {mountedValue(
        (animation, visible) =>
          visible && (
            <AnimatedBlock
              className="headerdropdown"
              style={{
                transformOrigin: "80% 0%",
                marginTop: 10,
                right: 0,
                opacity: animation.value,
                transform: interpolate(animation.value, [0, 1], outputRange),
              }}>
              <ul
                className="headerdropdown-links"
                onClick={() => setDropdownVisible(false)}>
                {path.map((item, index) => (
                  <li
                    key={index}
                    className="headerdropdown-links-item"
                    onClick={() => navigate(item.path)}>
                    <div className="links-item-icon">{item.icon}</div>

                    <div className="links-item-text">
                      <h3>{item.name}</h3>
                      {/* <p>Amet magna tempor enim in sit ex nisi ad commodo.</p> */}
                    </div>
                  </li>
                ))}
              </ul>

              <div className="headerdropdown-info">
                <div
                  className="headerdropdown-info-image"
                  style={{ backgroundImage: `url(${headerImage})` }}></div>

                <div className="headerdropdown-info-text">
                  <h3>{headerTitle}</h3>
                  <p>{headerDesc}</p>
                </div>
              </div>
            </AnimatedBlock>
          ),
      )}
    </div>
  );
};

export default HeaderDropdown;
