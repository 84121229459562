import React, { useEffect } from "react";
import { useAuth, useNavigation } from "react-uicomp";
import CardWrapper from "../hoc/compWrapper/CardWrapper.hoc";
import CompWrapper from "../hoc/compWrapper/CompWrapper.hoc";
import { DetailCarousel } from "../common/detailCarousel/DetailCarousel.common";
import ActivityIndicator from "../hoc/activityIndicator/ActivityIndicator.hoc";
import { getCurrentLanguage } from "../../utils/getCurrentLanguage.util";

// ACTIONs
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getEventDetailAction } from "../../actions/Actions";

const EventDetailPage = (props) => {
  const { setHeaderTitle, language } = useAuth();
  const { params } = useNavigation();
  const { id } = params;
  const { getEventDetailAction, event } = props;
  const { detailLoader, detail } = event;
  const currentLanguage = getCurrentLanguage(language);

  useEffect(() => {
    setHeaderTitle(detail?.description.title[currentLanguage]);
  }, [setHeaderTitle, event, currentLanguage, detail]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getEventDetailAction(Number(id));
  }, [getEventDetailAction, id]);

  return (
    <CompWrapper>
      <CardWrapper>
        <ActivityIndicator animating={detailLoader}>
          {detail && (
            <div className="news-detail-wrapper">
              <div className="container">
                <div className="detail">
                  <div className="title">
                    {detail.description.title[currentLanguage]}
                  </div>
                  <div className="image">
                    <DetailCarousel data={detail?.images} />
                  </div>
                  <div
                    className="paragraph"
                    dangerouslySetInnerHTML={{
                      __html: detail.description.desc[currentLanguage],
                    }}
                  />
                  <a
                    href={detail.link}
                    target="_blank"
                    rel="noopener noreferrer">
                    {detail?.link}
                  </a>
                </div>
              </div>
            </div>
          )}
        </ActivityIndicator>
      </CardWrapper>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    event: state.event,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEventDetailAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailPage);
