import React, { useEffect } from "react";
import { useAuth } from "react-uicomp";
import { FILE_URL } from "../../../config/Api.config";
import { getCurrentLanguage } from "../../../utils/getCurrentLanguage.util";
const CollaboratorCard = ({ data }) => {
  const { language } = useAuth();
  const currentLanguage = getCurrentLanguage(language);
  const description = data?.description;
  const name = description?.name[currentLanguage];

  useEffect(() => {
    // console.log("collaborators", data);
  }, []);
  return (
    <div
      className="collaboratorcard-container"
      onClick={() => window.open(data.description.website)}>
      <div className="collaboratorcard">
        <div className="logo">
          <img src={FILE_URL + data.image} alt="collaborator" />
        </div>
        <div className="text">{name}</div>
      </div>
    </div>
  );
};

export default CollaboratorCard;
