import React from "react"
import { useAuth } from "react-uicomp"
import { NoDataUI } from "../../common/noDataUI/noDataUI.common"
import { getCurrentLanguage } from "../../../utils/getCurrentLanguage.util"

export const TeamBio = ({ data }) => {
    const { language } = useAuth()
    const { isNepali } = language
    const currentLanguage = getCurrentLanguage(language)

    return (
        <div className="teamBio-container">
            <div className="teamBio">
                <div className="teamBio-heading">
                    {isNepali ? data?.description?.name?.np + 'को बारेमा' : 'About ' + data?.description?.name?.en}
                </div>
                <div className="teamBio-body">
                    {data?.bio ? data?.bio?.[currentLanguage] : <NoDataUI />}
                </div>
            </div>
        </div>
    )
}