import React, { useState } from "react";
import { AnimatedBlock, interpolate, useAuth, useMountedValue } from "react-uicomp";
import Title from "../../common/title/Title.common";
import { FILE_URL } from "../../../config/Api.config";
import { getCurrentLanguage } from "../../../utils/getCurrentLanguage.util";

import { MEMBER_TYPES } from "../constant/memberTypes.constant";
import { TeamBio } from "./teamBio.component";
import { CloseButton } from "../../common/button/Button.common";

export const Team = ({ teams }) => {
  const { language } = useAuth();
  const currentLanguage = getCurrentLanguage(language);
  const [modalVisible, setModalVisible] = useState(false);
  const mountedValue = useMountedValue(modalVisible, [0, 1, 0], {
    exitDuration: 100,
  });
  const [teamBioData, setTeamBioData] = useState()

  const handleCardClick = (item) => {
    setTeamBioData(item)
    setModalVisible(true)
  }

  return (
    <div className="ourteam-wrapper">
      <div className="container">
        {Object.keys(teams).map((val, index) => {
          return teams[val].length > 0 ? (
            <div className="teams" key={index}>
              <Title title={MEMBER_TYPES[val][currentLanguage]} hide_SeeAll />

              <div className="cards-container">
                {teams[val].map((item, index) => {
                  const description = item?.description;
                  const image = description?.image;
                  const name = description?.name[currentLanguage];
                  const designation = description?.designation[currentLanguage];
                  const address = description?.address[currentLanguage];
                  const bio = item?.bio?.[currentLanguage];

                  return (
                    <div className="wrapper" key={index}>
                      <div className="cards" onClick={() => handleCardClick(item)}>
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${FILE_URL}/${image})`,
                            backgroundPosition: "center",
                          }}></div>
                        <div className="information">
                          <div className="name">{name}</div>
                          <div className="designation">{designation}</div>
                          <div className="address">{address}</div>
                          <div className="bio">{bio}</div>
                        </div>
                      </div>
                      {mountedValue(
                        (animation, mounted) =>
                          mounted && (
                            <AnimatedBlock
                              style={{ opacity: animation.value }}
                              className="teamBio-modal-container">
                              <AnimatedBlock
                                style={{
                                  position: "relative",
                                  transformOrigin: "80% 80%",
                                  top: interpolate(animation.value, [0, 1], [100, 0]),
                                  transform: interpolate(
                                    animation.value,
                                    [0, 1],
                                    ["scale(0.9)", "scale(1)"],
                                  ),
                                }}
                                className="teamBio-modal">
                                <div className="teamBio-modal-button">
                                  <CloseButton onClick={() => setModalVisible(false)} />
                                </div>
                                <TeamBio data={teamBioData} />
                              </AnimatedBlock>
                            </AnimatedBlock>
                          ),
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};
