import React, { useEffect } from "react";
import { useAuth } from "react-uicomp";

import { Intro, Information } from "./components";

const IntroductionPage = () => {
  const { setHeaderTitle, language } = useAuth();

  useEffect(() => {
    setHeaderTitle(language.key.introduction);
  }, [setHeaderTitle, language.key.introduction]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Intro />
      <Information />
    </div>
  );
};

export default IntroductionPage;
