import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FILE_URL } from "../../../config/Config";

export const DetailCarousel = ({ data }) => {
  const settings = {
    customPaging: function (i) {
      return <div className="dots"></div>;
    },
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div class="research-carousel-container">
      <Slider {...settings}>
        {data?.map((val, i) => {
          return (
            <div class="wrapper">
              <img src={FILE_URL + val} alt="detail" className="slide-image" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
