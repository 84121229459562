import React from "react";
import { useAuth, useNavigation } from "react-uicomp";
import H1 from "../../../assets/gallery/h1.jpg";

import SeeMore from "../../common/seemore/Seemore.common";
export const Introduction = () => {
  const { language } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;

  return (
    <div className="introduction-container">
      <div className="introduction">
        <div
          className="introduction-image-container"
          style={{
            backgroundImage: `url(${H1})`,
          }}></div>

        <div className="introduction-desc">
          <div className="introduction-desc-title">{language.key.whoweare}</div>

          <p className="introduction-desc-content">
            {language.key.homepage.introductionfull?.para1}<br /><br />{language.key.homepage.introductionfull?.para2}
          </p>

          <div
            className="introduction-desc-footer"
            onClick={() => {
              navigate(routes.introduction.path);
            }}>
            <SeeMore title={language.key.seeall} />
          </div>
        </div>
      </div>
    </div>
  );
};
